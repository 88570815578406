<template>
  <div>
    <!-- <div class="liveTop flex flex-center">
      <div class="flex flex-center liveTopLeft">
        <div>全部员工</div>
        <img class="liveTop-icon" src="../assets/imgs/triangle.png" alt="" />
      </div>
      <div class="liveTop-btn flex-item_center" @click="router('/CreateCode')">
        新建
      </div>
    </div> -->

    <div class="liveCard rel" v-for="(item, index) in list" :key="index">
      <img
        class="liveCard-upload abs"
        src="../assets/imgs/uploadCode.png"
        alt=""
      />
      <div class="liveCard-top flex flex-center">
        <img class="liveCard-img" :src="item.qr_code" alt="" />
        <div>
          <div class="liveCard-name">{{ item.title }}</div>
          <div class="liveCard-num">扫码人数 {{item.member_count}}人</div>
        </div>
      </div>
      <div class="liveCard-people">
        <!-- 使用成员: 张东海、李小双、李海波 -->
      </div>
      <div class="liveUnderline"></div>
      <div class="liveTime flex flex-center">
        <div>{{ item.add_time }}</div>
        <img class="liveTime-icon" src="../assets/imgs/editText.png" alt="" />
      </div>
    </div>
    <div class="padding-b"></div>
    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-btn flex-item_center"  @click="router('/CreateCode')">新建</div>
      <div class="bottom-btn_save flex-item_center" @click="router('/MyCode')">
        我的企微二维码
      </div>
    </div>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      list:[]
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      let res = await request._getQrCodeList({
        userid: localStorage.getItem("userid"),
      });
      console.log("首页 res", res);
      if (_.toInteger(res.code) === 1) {
        this.list = res.data.data;
      }
    },
    router(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.liveTop {
  width: 100%;
  padding: 0 30px;
  border-top: 1px solid #c3c3c3;
  height: 131px;
  justify-content: space-between;
  background: #ffffff;
}

.liveTopLeft {
  padding: 15px 30px;
  background: #f4f5f7;
  border-radius: 8px 8px 8px 8px;
}

.liveTop-icon {
  width: 12px;
  height: 8px;
  margin-left: 22px;
}

.liveTop-btn {
  width: 120px;
  height: 45px;
  background: #0558f4;
  border-radius: 8px 8px 8px 8px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.liveCard {
  width: 690px;
  height: 368px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  margin: 17px auto;
}

.liveCard-top {
  padding: 30px 30px 0 30px;
}

.liveCard-img {
  width: 146px;
  height: 146px;
  margin-right: 15px;
}

.liveCard-name {
  font-size: 36px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  margin-bottom: 45px;
}

.liveCard-num {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
}

.liveCard-people {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
  padding: 30px;
}

.liveUnderline {
  width: 100%;
  height: 1px;
  background: #d6e5ee;
}

.liveTime {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  justify-content: space-between;
  color: #7d8393;
  padding: 30px;
}

.liveTime-icon {
  width: 93px;
  height: 37px;
}

.liveCard-upload {
  width: 105px;
  height: 37px;
  top: 40px;
  right: 30px;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-btn {
  width: 325px;
  height: 80px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #0558f4;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
  margin: 15px 0 0 0;
}

.bottom-btn_save {
  width: 325px;
  margin: 15px 0 0 20px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

.padding-b {
  height: 200px;
}
</style>
